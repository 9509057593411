<template>
  <div class="users-trash md:w-1/2 mx-auto fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="courtsTrashList" :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @user:restore="restoreCourt($event)"
                             @user:deleted="$refs.deleteConfirmation.showDialog(), userDataForDelete = $event"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getCourtsTrash()"/>

    <custom-dialog ref="deleteConfirmation"
                   :title="$t('courts.confirmations.forceDelete.title')"
                   :body="$t('courts.confirmations.forceDelete.body', {name: userDataForDelete.name})"
                   @accept="deleteCourt(userDataForDelete)"
                   @close=""/>
  </div>
</template>

<script>
  import axios from 'axios'
  import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
  import CustomDialog from '@/components/customDialog/customDialog'
  import {deleteCourt, getCourtsTrash, restoreCourt} from '@/http/requests/club/courts'
  import {checkUserPermissions} from '@/assets/js/functions'

  export default {
    name: 'usersTrash',
    components: {CustomDialog, DraggableDynamicTable},
    data() {
      return {
        options: {
          id: 'courtsTrashList',
          rowKeyField: 'id'
        },
        loadingTimer: 0,
        columnsLabel: [
          {
            field: 'storeAction',
            i18n: 'courts.list.table.header.restore',
            color: 'success',
            width: '70px',
            minWidth: 70,
            locked: true,
            actions: true,
            showAction: 'always',
            event: 'user:restore',
            action: {
              icon: 'REFRESH',
              iconPack: 'useral',
              color: 'success',
              type: 'button'
            },
            classes: 'mx-auto'
          },
          {
            field: 'deleteAction',
            i18n: 'courts.list.table.header.delete',
            color: 'danger',
            width: '70px',
            minWidth: 70,
            align: 'center',
            locked: true,
            actions: true,
            showAction: 'always',
            event: 'user:deleted',
            action: {
              icon: 'TRASH',
              iconPack: 'useral',
              color: 'danger',
              type: 'button'
            },
            classes: 'mx-auto'
          },
          {
            field: 'name',
            i18n: 'courts.list.table.header.name',
            width: 'calc((100% / 10) * 2)',
            minWidth: 250,
            sortable: true,
            // locked: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'type',
            i18n: 'courts.list.table.header.type',
            width: 'calc(100% / 10)',
            minWidth: 100,
            align: 'center',
            sortable: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('courts.types.all'),
                value: 0
              },
              {
                label: this.$t('courts.types.hard'),
                value: 1
              },
              {
                label: this.$t('courts.types.ground'),
                value: 2
              },
              {
                label: this.$t('courts.types.grass'),
                value: 3
              }
            ]
          },
          {
            field: 'row',
            i18n: 'courts.list.table.header.row',
            width: '50px',
            minWidth: 50,
            align: 'center',
            footer: {
              /*type: 'auto-counter'*/
            }
          }
        ],
        data: [],
        filters: [],
        sorts: ['order[0]=created_at,asc'],
        page: 1,
        endedList: false,
        actions: {
          leftToolbar: [
            {
              id: 'printTable',
              icon: 'PRINT',
              iconPack: 'useral'
            },
            {
              id: 'downloadTable',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: {name: 'courts'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        },
        userDataForDelete: {}
      }
    },
    created() {
      if (!checkUserPermissions('court.forceDelete')) {
        for (let i = 0; i < this.columnsLabel.length; i++) {
          if (this.columnsLabel[i].field === 'deleteAction') {
            this.columnsLabel.splice(i, 1)
          }
        }
      }

      if (!checkUserPermissions('court.restore')) {
        for (let i = 0; i < this.columnsLabel.length; i++) {
          if (this.columnsLabel[i].field === 'storeAction') {
            this.columnsLabel.splice(i, 1)
          }
        }
      }

      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', ' md:w-1/2 mx-auto')
      }, 50)

      this.getCourtsTrash()
    },
    methods: {
      getCourtsTrash() {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.courtsTrashList && this.data.length === 0) {
              this.$refs.courtsTrashList.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.courtsTrashList && this.data.length > 0) {
              this.$refs.courtsTrashList.loadMoreStatus = 'Loading'
            }

            getCourtsTrash(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              res.data.data.forEach((court) => {
                let courtType = ''
                if (court.type === 1) {
                  courtType = this.$t('courts.types.hard')
                } else if (court.type === 2) {
                  courtType = this.$t('courts.types.ground')
                } else if (court.type === 3) {
                  courtType = this.$t('courts.types.grass')
                }

                this.data.push({
                  row: this.data.length + 1,
                  id: court.id,
                  registryDate: court.created_at,
                  gender: court.gender === 1 ? this.$t('genderTypes.man') : this.$t('genderTypes.woman'),
                  phoneNumber: {
                    value: court.phone_number ? court.phone_number : '',
                    classes: this.$vs.rtl ? 'rtl' : 'ltr'
                  },
                  name: court.name || '',
                  type: courtType,
                })
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              if (this.$refs.courtsTrashList) this.$refs.courtsTrashList.loadMoreStatus = ''
            })
                .catch((error) => {
                  if (this.$refs.courtsTrashList && !axios.isCancel(error)) this.$refs.courtsTrashList.loadMoreStatus = 'Danger'
                })
          }
        }, 400)
      },
      setFilters (filters) {
        const filters_list = []
        Object.keys(filters).forEach((key) => {
          switch (key) {
            case 'name':
              if (filters[key].search !== '') filters_list.push(`name=${  filters[key].search}`)
              break

            case 'type':
              if (filters[key].search.value > 0) filters_list.push(`type=${  filters[key].search.value}`)
              break
          }
        })
        filters_list.push('characters=1')

        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list
        this.getCourtsTrash()
      },
      setSort (columns) {
        const sorts_list = []
        Object.keys(columns).forEach((key) => {
          switch (key) {

            case 'row':
              sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'name':
              sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'type':
              sorts_list.push(`order[0]=type,${  columns[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

        this.data = []
        this.page = 1
        this.endedList = false
        this.sorts = sorts_list
        this.getCourtsTrash()
      },
      restoreCourt(court) {
        restoreCourt(court.id).then(res => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('courts.notifications.restore.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check-circle',
            iconPack: 'feather'
          })

          this.data = []
          this.getCourtsTrash()
        })
          .catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('courts.notifications.restore.error'),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400
              })
            }
          })
      },
      deleteCourt(court) {
        deleteCourt(court.id, true).then(res => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('courts.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check-circle',
            iconPack: 'feather'
          })

          this.data = []
          this.getCourtsTrash()
        })
          .catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              switch (error.response.status) {
                case 409:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('courts.notifications.delete.isUsedError'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 5000
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('courts.notifications.delete.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
      }
    }
  }
</script>

<style scoped>

</style>
